<template>
    <div class="business">
        <div class="row">
            <div class="col-md-8">
                <h3>受託開発</h3>
                <div class="p-3">
                    <p>
                        お客様が解きたい課題を弊社技術スタッフが一緒に課題整理を行いながら、ご要望に合わせた受託開発を承っております。
                        <br /><br />
                        常にお客様に最も効率的にビジネスを行っていただくことを念頭に、お客様に寄り添い、ご要望をくみ取りながら、
                        長年、多岐にわたるプロジェクトに携わってきたシステム開発のスペシャリストチームより、
                        コンサルティングから保守運用までワンストップで一貫したサービスをご提供しており、
                        お客様のビジネス課題は変化に柔軟に対応することであると確信しています。
                    </p>
                </div>
            </div>
            <div class="col d-flex align-items-center">
                <img src="../../assets/serivce_1.png" alt="受託開発" class="img-fluid" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-8">
                <h3>SES事業</h3>
                <div class="p-3">
                    <p>
                        経験豊かなITエンジニアと技術を求めている企業をマッチングし、プロジェクト単位の技術支援や開発支援を提供します。
                        <br /><br />
                        当社では、多くのビジネスパートナーと協業し、システム開発に詳しい営業スタッフがお客様への対応を行うため、
                        ニーズに即した優秀なエンジニアの提案が可能です。
                        また必要に応じて数十名の人材を集め、チーム単位でSES提案することができるのも強みのひとつです。
                    </p>
                </div>
            </div>
            <div class="col d-flex align-items-center">
                <img src="../../assets/serivce_2.png" alt="受託開発" class="img-fluid" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-8">
                <h3>自社製品開発</h3>
                <div class="p-3">
                    <p class="text-left">
                        自社のビジネスを円滑かつ効率的に進め、生産性をアップするために、自社製品開発に反映させる形で実現し、商品化します。
                        <br /><br />
                        自社の技術を活かせそうなことを探すことから始め、まず自社業務に目を向け、
                        「困っていることは何か」、「業務効率をよりよくするために必要なものは何か」などを思考し、製品の開発に取り込みます。
                    </p>
                    <h4>製品一覧</h4>
                    <ul>
                        <li>
                            <a href="/service/product01">ホームページ</a>
                        </li>
                        <li>
                            <a href="/service/product02">IT人材管理システム（ITDMS）</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col d-flex align-items-center">
                <img src="../../assets/serivce_3.png" alt="受託開発" class="img-fluid" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-8">
                <h3>ITトレーニング（準備中）</h3>
                <div class="p-3">
                    <p>
                        企業のニーズに合わせたカスタマイズされたソリューションを提供し、従業員のスキルアップと組織の成長を支援します。<br>
                        専門知識の提供や実践的な学習体験を通じて、従業員の能力向上やキャリア形成を効果的に支援し、組織全体の競争力を強化することができます。
                    </p>
                    <ul>
                        <li>IT経験がない方向けの初心者向けコースもご用意しています。</li>
                        <li>オンラインでのトレーニングを行い、場所を選ばず効果的な学習が可能です。</li>
                        <li>日本語と中国語両方のトレーニングも提供しています。日本語がわからない中国の方も安心してご利用可能です。</li>
                        <li>受講者の理解度やスキルレベルに合わせてトレーニング内容を調整します。</li>
                        <li>実践的なプロジェクトや演習を通じて、リアルな業務シーンでのスキル習得を促進します。</li>
                    </ul>
                    <h4>人材開発支援助成金制度の活用サポート</h4>
                    <p>
                        企業が従業員のスキルアップや組織の成長を促進するために活用できる重要な制度です。<br>
                        この助成金は、研修やトレーニングにかかる費用の一部を補助することで、企業の負担を軽減し、人材開発活動を支援します。
                    </p>
                </div>
            </div>
            <div class="col d-flex align-items-center">
                <img src="../../assets/serivce_4.png" alt="受託開発" class="img-fluid" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BusinessPage",
    components: {},
    data: function () {
        return {};
    },
    methods: {},
};
</script>

<style scoped>
.business a {
    color: #28a7e1;
    text-decoration: none;
}

.business a:hover {
    text-decoration: underline;
}
</style>

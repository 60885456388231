<template>
    <div class="service">
        <div class="row">
            <div class="col">
                <h3>事業内容</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="card m-3">
                    <img src="../../assets/serivce_1.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">受託開発</h5>
                        <p class="card-text">お客様が解きたい課題を弊社技術スタッフが一緒に課題整理を行いながら、ご要望に合わせた受託開発を承っております。</p>
                        <p class="card-text text-end">
                            <small><a href="/service/business">詳しくはこちら</a></small>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card m-3">
                    <img src="../../assets/serivce_2.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">SES事業</h5>
                        <p class="card-text">経験豊かなITエンジニアと技術を求めている企業をマッチングし、プロジェクト単位の技術支援や開発支援を提供します。</p>
                        <p class="card-text text-end">
                            <small><a href="/service/business">詳しくはこちら</a></small>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card m-3">
                    <img src="../../assets/serivce_3.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">自社製品開発</h5>
                        <p class="card-text">自社のビジネスを円滑かつ効率的に進め、生産性をアップするために、自社製品開発に反映させる形で実現し、商品化します。</p>
                        <p class="card-text text-end">
                            <small><a href="/service/business">詳しくはこちら</a></small>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card m-3">
                    <img src="../../assets/serivce_4.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">ITトレーニング（準備中）</h5>
                        <p class="card-text">企業のニーズに合わせて、独自のトレーニングソリューションを開発し、従業員のスキルアップや組織の成長を支援します。</p>
                        <p class="card-text text-end">
                            <small><a href="/service/business">詳しくはこちら</a></small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ServiceSection",
};
</script>

<style scoped>
.service a {
    color: #28A7E1;
    text-decoration: none;
}

.service a:hover {
    text-decoration: underline;
}
</style>
